import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Breadcrumb from "../components/Global/Breadcrumb"

import User from "./../images/user.svg"
import { el } from 'date-fns/locale'
import Calendar from "./../images/calendar.svg"

// import RelatedPosts from '../components/RelatedPosts'
import htmlDecode from "html-entities-decoder";
import { format } from 'date-fns'

// import "../components/Blog/PostTemplate.css"


// import {
//     EmailShareButton,
//     FacebookShareButton,
//     WhatsappShareButton,
//     FacebookIcon,
//     WhatsappIcon,
//     EmailIcon
// } from "react-share";


function BlogPostTemplate(props) {
    let data = props.data.wpgraphql;

    // console.log(props)

    function preloadImage(img) {
        const src = img.getAttribute("data-src");
        if (!src) {
            return;
        }
        img.src = src;
    }

    const imgOptions = {
        threshold: 0,
        rootMargin: "0px 0px 300px 0px"
    };

    useEffect(() => {

        // let imgObserver = new IntersectionObserver((entries, imgObserver) => {
        //     entries.forEach(entry => {
        //         if (!entry.isIntersecting) {
        //             return;
        //         } else {
        //             preloadImage(entry.target);
        //             imgObserver.unobserve(entry.target);
        //         }
        //     })
        // }, imgOptions)

        // let images = document.querySelectorAll("[data-src]");
        // images.forEach(image => {
        //     imgObserver.observe(image)
        // })

    }, [])


    // function addLazyLoadHandler(content) {
    //     let lazyLoadedContent;
    //     lazyLoadedContent = content.replace(/src="/g, 'data-src="')
    //     lazyLoadedContent = lazyLoadedContent.replace(/srcset="/g, 'data-srcset="')
    //     return lazyLoadedContent;
    // }


    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://karanikolascon.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://karanikolascon.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.post.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            recentPosts = { data.posts }
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            metaData={constructMetaData(data.post, props.pageContext.currentPage, props.pageContext.language)}
            services={props.pageContext.servicesCategories}
            projects={props.pageContext.projectsCategories}
            preHeader={data.template.contactInformationTemplate}
        >
            {data.post.breadcrumbAcf.breadcrumbName && (
                <section>
                    <Breadcrumb list={getBreadcrumb()} image={data.post.breadcrumbAcf.image} h1={data.post.breadcrumbAcf.breadcrumbName} />
                </section>
            )}
            <article className="container mx-auto mt-16 px-4 flex flex-wrap" style={{ maxWidth: "1170px" }}>
                <div className="w-full lg:w-2/3 pb-0 lg:pb-8">
                    <div className="sp-img-container w-full relative" >
                        {data.post.featuredImage && (
                            <GatsbyImage
                                image={data.post.featuredImage.node.imageFile.childImageSharp.gatsbyImageData}
                                alt={data.post.featuredImage.node.altText}
                                className=" w-full pb-6 sp-f-img" />
                        )}
                    </div>
                    <div className="flex blog-info py-6 mb-4" style={{ borderBottom: "1px solid rgba(0,0,0,0.08)" }}>
                        <div className="flex justify-center">
                            <div>
                                <img src={User} style={{ width: "15px", marginRight: "5px" }} />
                            </div>
                            <div>
                                {data.post.author.node.firstName}
                            </div>
                        </div>
                        <div className="px-2">-</div>
                        <div className="flex justify-center">
                            <div>
                                <img src={Calendar} style={{ width: "15px", marginRight: "5px" }} />
                            </div>
                            <div>{format(new Date(data.post.date), 'dd MMMM, yyyy', { locale: el })}</div>
                        </div>
                    </div>
                    <div>
                        <div className="sp-content-wrapper">
                            <div dangerouslySetInnerHTML={{ __html: data.post.content }} />
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-1/3 pl-0 lg:pl-8 py-8 lg:py-0">

                    {(data.post.relatedPosts.relatedPosts && data.post.relatedPosts.relatedPosts.length) > 0 && (
                        <div className="side-blog-tab">
                            <div>
                                <h3>Σχετικά Άρθρα</h3>
                            </div>
                            <div>
                                {data.post.relatedPosts.relatedPosts.length > 0 && data.post.relatedPosts.relatedPosts.map((rp, i) => (
                                    <Link to={"/blog" + rp.uri} className={`flex ${data.post.relatedPosts.relatedPosts.length !== i+1 ? "pb-4" : "pb-0"}`} key={`related-post-${i}`}>
                                        <div>
                                            <GatsbyImage
                                                image={rp.featuredImage.node.imageFile.childImageSharp.gatsbyImageData}
                                                alt={rp.featuredImage.node.altText}
                                                className="w-full" />
                                        </div>
                                        <div className="rp-rl-bl pl-4">
                                            <div>{format(new Date(rp.date), 'dd MMMM, yyyy', { locale: el })}</div>
                                            <h4>{rp.title}</h4>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </article>
        </Layout>
    );
}

export default BlogPostTemplate

export const pageQuery = graphql`query BLOG_POST_QUERY($id: ID!, $themeTemplateUri: ID!) {
  wpgraphql {
    post(id: $id) {
      breadcrumbAcf {
        breadcrumbName
        image {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
      slug
      title
      content
      date
      author {
        node {
          name
          firstName
          avatar {
            url
          }
        }
      }
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      featuredImage {
        node {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 780
                quality: 100
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      relatedPosts {
        relatedPosts {
          ... on WPGraphQL_Post {
            uri
            slug
            title
            date
            author {
              node {
                firstName
              }
            }
            featuredImage {
              node {
                sourceUrl
                altText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(width: 100, quality: 100, placeholder: BLURRED, layout: FIXED)
                  }
                }
              }
            }
          }
        }
      }
    }
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        mobileNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    
  }
}
`
